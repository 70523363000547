'use client';
import { useEffect, useMemo, useOptimistic, useState, useTransition } from 'react';
import { StandardCardsGrid } from '../StandardCardsGrid';
import { ALPHABETICAL_SORT_LABEL, DEFAULT_ROOT_CATEGORIES_SLUG } from '@/shared/utils/constants';
import { Badge } from '@/shared/api/arena-data';
import { ShortGameModelFromFeed } from '@/games';
import { usePathname, useRouter } from 'next/navigation';
import styles from './styles.module.css';
import classNames from 'classnames';
import { THorizontalPills, TPill } from '@/components/HorizontalPills/model';
import HorizontalPills from '../HorizontalPills';
import { Dropdown } from '../Dropdown';
import React from 'react';
import { useIsDesktop } from '@/shared/utils/useIsDesktop';
import { useScreenSize } from '@/shared/utils/useScreensize';
import { ALL_CATEGORY } from '@/shared/utils/constants';
import { getLocaleFromPathname } from '@/shared/utils/url';
import { EAriaCurrentEnum } from '@/components/Pill/model';

export const HeadlineCardsGrid = (props: {
	feed: ShortGameModelFromFeed[];
	rootCategorySlug?: string | null;
	initialFilter?: string | null;
	title?: string | null;
	query?: string | null;
	hideCategories?: boolean;
	arenaBadges?: Badge[];
	allCategories?: string[];
	sortOptions?: string[];
	filterConfig?: THorizontalPills;
	isSearchPage?: boolean;
}) => {
	const router = useRouter();
	const pathname = usePathname();
	const [isPending, startTransition] = useTransition();
	const screenSize = useScreenSize();
	const [currentFilter, setCurrentFilter] = useOptimistic<string | undefined | null>(
		props?.initialFilter ?? ALL_CATEGORY.name,
	);

	const games = props?.feed ?? [];
	const locale = getLocaleFromPathname(pathname);

	const categories = useMemo(() => {
		if (props?.hideCategories) {
			return [];
		}
		return props?.allCategories ?? [];
	}, [props.hideCategories, props.allCategories]);

	function filterFeed(selectedCategory: string, href: string) {
		startTransition(() => {
			setCurrentFilter(selectedCategory);
			router.push(`/${locale}${href}`);
		});
	}

	const isDesktop = useIsDesktop();

	const onSelect = (pill: TPill) => {
		filterFeed(pill.label, pill.href);
	};

	const horizontalPillsComponent = (() => {
		if (props.hideCategories || !props.filterConfig) {
			return null;
		}

		const config = {
			...props.filterConfig,
			onSelect: onSelect,
			pills: props.filterConfig.pills.map((pill) => ({
				...pill,
				isActive: pill.label.toLowerCase() === currentFilter?.toLowerCase(),
			})),
			ariaCurrent: EAriaCurrentEnum.Page,
		};

		return categories.length > 0 ? <HorizontalPills config={config} index={0} isPillsFromPages /> : null;
	})();

	const onSortFeed = (by: string) => {
		startTransition(() => {
			startTransition(() => {
				const params = new URLSearchParams(window.location.search);
				params.set('sort', by);
				router.push(`${pathname}?${params.toString()}`);
			});
		});
	};
	return (
		<>
			<div className={classNames(styles.header, 'headers')}>
				<h2 className={styles.headline}>
					{props.title} {props?.query && <span className={styles.query}>“{props.query}”</span>}
				</h2>
				{props.sortOptions?.length && (
					<Dropdown
						items={props.sortOptions}
						onUpdate={(e: string) => {
							onSortFeed(e);
						}}
					/>
				)}
				{!isDesktop && horizontalPillsComponent}
			</div>
			{isDesktop && horizontalPillsComponent}
			{games.length > 0 && (
				<StandardCardsGrid
					games={games}
					rootCategorySlug={props?.rootCategorySlug ?? DEFAULT_ROOT_CATEGORIES_SLUG}
					arenaBadges={props?.arenaBadges ?? []}
					screenSize={screenSize}
					isSearchPage={props?.isSearchPage}
					isPending={isPending}
				/>
			)}
		</>
	);
};
